import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { AccountsRoutingModule }   from './accounts-routing.module';
import { AccountListComponent }    from './account-list/account-list.component';
import { AccountEditComponent, DialogComponent }         from './account-edit/account-edit.component';

import { AccountAppEditComponent } from './account-app-edit/account-app-edit.component';

import { AccountService}          from './account.service';

import { RolesModule}          from './../roles/roles.module';
import { OrganizationsModule}  from './../organizations/organizations.module';
import { SharedModule }        from './../shared/shared.module';
import { LanguageService } from './language.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    AccountsRoutingModule,
    RolesModule,
    OrganizationsModule,
    SharedModule
  ],
  declarations: [
    AccountListComponent,
    AccountEditComponent,
    AccountAppEditComponent,
    DialogComponent
  ],
  providers: [
    AccountService,
    LanguageService
  ],
  exports: [
    AccountListComponent,
    AccountAppEditComponent
  ]
})
export class AccountsModule { }
