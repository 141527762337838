<h1 mat-dialog-title>Calendar settings</h1>
<div mat-dialog-content>
    <div class="explanation">
      <p>The machine's calendar is a feature that allows us to:</p> 
      <ul>
        <li>Specify work schedules through shifts.</li>
        <li>Indicate shifts or holidays.</li>
        <li>Add special working days.</li>
      </ul>
    </div>

    <div class="config">
      <p>To set up a calendar, follow these steps:</p>
      <ol>
        <li>Go to the organization to which the machine belongs and set up the different shifts performed in the company under the "Shifts" tab.</li>
        <li>Then, click on the pencil icon in this menu to edit and set up the shifts the machine works and the days of the week it operates.</li>
        <li>Once saved, you will see the calendar updated with the indicated shifts for the setd up days.</li>
        <li>If, for any reason, a shift is not worked on a specific day, simply click on that day and deactivate the shift(s) that were not worked in the pop-up window.</li>
        <li>If, for any reason, there is an exceptional workday, you can select the day and add the shift that was worked.</li>
      </ol>
    </div>
    
    <div class="appendix">
      <p>
        <span>Remember, new shifts can only be added for future dates; you cannot add a shift to a past day.</span>
        <br>
        <span>However, you can deactivate shifts for past days, as explained in point 4 above.</span>
      </p>
    </div>
</div>
