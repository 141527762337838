import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';

import { environment } from "../../../../environments/environment";

//import { Observable, of as observableOf} from 'rxjs';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var _:any;
import * as moment from 'moment';
import { QueryStore } from '../store/query.store';

/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'tableListHttp',
  styleUrls: ['./tableListHttp.component.css'],
  templateUrl: './tableListHttp.component.html',
})

export class TableListHttpComponent implements OnInit {
  constructor(
    public dialog2: MatDialog,
    private _location: Location,
    private queryStore: QueryStore,
  ){}
  @Input() module: string;
  @Input() table_headers: Array<string>;
  // @Input() fields: Array<any>;
  @Input() set fields(fields){
    if (fields && fields.length > 0) {
      this._fields = fields;
      this.idFields = this._fields.map(value => value.id);
    }
  }
  @Input() data: Response;
  @Input() canCreate: boolean;
  @Input() canDelete: boolean;
  @Input() loading: boolean;
  @Input() menuTitle: string;
  @Input() query: any;
  @Input() hideBackButton: boolean = true;
  // @Input() set query(query){
  //   this.updateQuery(query);
  // };
  @Input() insideEntity: boolean =false;
  @Input() insideLicense: boolean =false;
  @Input() csvButton: boolean =false;
  
  @Output() tableListEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  _fields: any[] = [];
  filter: any = {};
  idFields: string[] = [];
  bbjVariables4DeleteObject: any[] = [];
  bbjVariables4DeleteId: number[] = [];
  cardHeaderColor = environment.card_header_color;

  _query: {
    pageSize: number,
    pageIndex: number,
    sort: string,
    direction: SortDirection,
    filter: string,
    pageSizeOptions: number[],
  };
  defaultQuery = {
    pageSize: 10,
    pageIndex: 0,
    sort: null,
    direction: null,
    filter: null,
    pageSizeOptions: [5, 10, 20],
  };
  url: string;
  queryStateId: {url: string, component: string};

  private observableActive: boolean = false;

  expandedElement: any = null;

  ngOnInit() {

    this.initQuery();
    this.applyQuery(); 

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // If the user changes the paginator, refresh data.
    this.paginator.page.subscribe(() => this.getData());
    this.getData();
  }

  prevent(e: any) {
    e.preventDefault();
  }

  /**
   * Init the query status.
   */
  initQuery() {
    // Init the query id with {url, component} key pair
    this.url = this._location.path(false).split(/[?#]/)[0];
    this.queryStateId = {
      url: this.url,
      component: this.module
    };

    // Load query from query store
    let query = this.queryStore.getQuery(this.queryStateId);
    if(!query) {
      // If there is no query stored, init query with default values
      this._query = this.query;
      // Store the new query
      this.queryStore.addQuery(this.queryStateId, this._query.pageSize, this._query.pageIndex, this._query.sort, this._query.direction, this._query.filter);
    } else {
      // Init with the stored query
      this._query = query;
    }
  }

  /**
   * Apply the query to the table
   */
  applyQuery() {
     // this.idFields = this._fields.map(value => value.id);
    //initial paginator default values
    this.paginator.pageSize = (this._query.pageSize != null) ? this._query.pageSize : this.defaultQuery.pageSize;
    this.paginator.pageIndex = (this._query.pageIndex != null) ? this._query.pageIndex / this.paginator.pageSize : this.defaultQuery.pageIndex;
    this.paginator.pageSizeOptions = (this._query.pageSizeOptions != null) ? this._query.pageSizeOptions : this.defaultQuery.pageSizeOptions;

    this.sort.active = this._query.sort;
    this.sort.direction = this._query.direction;
    this.filter = this._query.filter || {};
  }

  applyFilter(field: string, value: string) {
    //organization filter exception
    if(field.split('.').pop() == 'organization') {
      field = field.concat('.name');
    }

    /*
     * If the filter value is empty, we need to delete that key
     * from the filter object so that null fields are also shown,
     * because an empty string is not the same as null.
     * We could use delete, but since delete operator is
     * too inefficient, we instead set the value to undefined.
     * Which, in essence, is the same.
     */
    this.filter[field] = (value == "") ? undefined : value;

    this.paginator.pageIndex = 0;
    
    if (!this.observableActive) {
      this.observableActive = true;
      if (this._fields && this._fields.length > 0) {
        this.loading = true;
      }
      setTimeout(() => {
        this.observableActive = false;
        this.getData();
      }, 1000);
    }
  }

  getData(){
    //organization sort exception
    if (this._fields && this._fields.length > 0) {
      let undo = false;
      if(_.endsWith(this.sort.active, 'organization')) {
        this.sort.active = this.sort.active.concat('.name');
        undo = true;
      }

      let query = {pageSize: this.paginator.pageSize, pageIndex: this.paginator.pageSize * this.paginator.pageIndex, sort: this.sort.active, direction: this.sort.direction, filter: this.filter};
      this.queryStore.updateQuery(
        this.queryStateId,
        query.pageSize,
        query.pageIndex,
        query.sort,
        query.direction,
        query.filter
      );
      this.tableListEmitter.emit({text: 'getData', data: query});

      //undo organization sort exception
      if(undo) {
        this.sort.active = this.sort.active.split(".").slice(0, -1).join(".");
      }
    }
  }


  capitalizeFirstLetter(string) {
    //Capitalize first letter of a string
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  lowerCaseFirstLetter(string) {
    //Lowerize first letter of a string
    if (string != "Vixion Edge" && string != "OEM" && string != "VPN IP") {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }
    else {
      return string;
    }
  }

  clickDelete(event, variable) {
      event.stopPropagation();
      if (!_.includes(this.bbjVariables4DeleteId, variable.idBBJVariable)) {
        this.bbjVariables4DeleteId.push(variable.idBBJVariable);
        this.bbjVariables4DeleteObject.push(variable);
      } else {
        _.remove(this.bbjVariables4DeleteId, item => item === variable.idBBJVariable);
        _.remove(this.bbjVariables4DeleteObject, item => item === variable);
      }
      return;
  }

  onBulkDelete(event: any, data: any) {
    this.tableListEmitter.emit({text: 'bulkDelete', data: data});
    // this.getData();
  }

  assignEntityToLicense(event: any, data: any) {
    this.tableListEmitter.emit({text: 'assignEntityToLicense', data: data});
    // this.getData();
  }

  change2FullMode(event: any, data: any) {
    if (event.stopPropagation) event.stopPropagation();
    this.tableListEmitter.emit({text: 'change2FullMode', data: data});
  }

  licenseSeatEdit(event: any, data: any) {
    if (event.stopPropagation) event.stopPropagation();
    this.tableListEmitter.emit({text: 'licenseSeatEdit', data: data});
  }

  onSort() {
    this.getData();
  }

  onNew() {
    this.tableListEmitter.emit({text: 'new'});
  }

  onCsv() {
    this.tableListEmitter.emit({text: 'csv'});
  }

  onSelect(event: any, data: any){
    this.tableListEmitter.emit({text: 'select', data: data});
  }

  onRefresh(event: any){
    this.tableListEmitter.emit({text: 'refresh'});
  }

  onEdit(event: any, data: any){
    if (event.stopPropagation) event.stopPropagation();
    this.tableListEmitter.emit({text: 'edit', data: data});
  }

  getValue(value: Object, field: string){
    //if(field == 'entities') {debugger}
    return _.get(value, field);
  }

  isEvaluationMode(value: Object, field: string){
    return _.get(value, field).evaluationMode;
  }

  combo() {
    this.tableListEmitter.emit({text: 'combo'});
  }

  openDialogDelete(event: any): void {
    event.stopPropagation();
    let dialogRef = this.dialog2.open(DialogComponent2, {
          data: {
            tittle: "Delete",
            body: "Are you sure?"
          }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.tableListEmitter.emit({text: 'remove'});
      }

    });
  }

  openDialog(event: any, data: any): void {
    event.stopPropagation();
    let dialogRef = this.dialog2.open(DialogComponent2,{
        data: { tittle: "Unassign machine from this license",
                body: "Take care that if you unassing this machine from the license, Vixion App will not work for the machine. Are you sure?"
              }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.tableListEmitter.emit({text: 'unassignEntityToLicense', data: data});
        // this.onUnassignEntityToLicense(data)
      }

    });
  }

  onBack(){
    this._location.back();
    // this.headerActionEmitter.emit({text: 'back'});
  }

  toggleExpand(event, element: any): void {
    event.stopPropagation();
    this.expandedElement = this.expandedElement === element ? null : element;
  }

}

// //DIALOG COMPONENT
//
@Component({
  selector: '[dialogtest]',
  template: `
    <h2 mat-dialog-title>{{ data.tittle }}</h2>
    <mat-dialog-content> {{ data.body }} </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">No</button>
      <button mat-button [mat-dialog-close]="true">Yes</button>
    </mat-dialog-actions>`
})
export class DialogComponent2 {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent2>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface Response {
  rows: any[];
  count: number;
}
