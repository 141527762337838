import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';

import { AuthService } from './../auth.service';
import { environment } from '../../environments/environment';
import { Angulartics2Matomo } from 'angulartics2/matomo';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare var componentHandler: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  mobileQuery: MediaQueryList;
  impersonating : boolean;
  headerColor : string;
  showEnvironment = !environment.production;
  host = location.host;
  @ViewChild('snav', { static: true }) snav!: MatSidenav;
  private unsubscribe$ = new Subject<void>();
  // account: any = JSON.parse(localStorage.getItem('account'));
  isSidenavOpened: boolean;
  private _mobileQueryListener: () => void;

  constructor(
    private authService: AuthService, 
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher,
    private angulartics2Matomo: Angulartics2Matomo,
    private router: Router
  ) {
    this.authService.getAccount().subscribe((account) => {
      window['_paq'].push(['setUserId', account.username]);
      angulartics2Matomo.startTracking();
      window['_paq'].push(['setCustomVariable', 1, "username", account.username, "visit"]);
      window['_paq'].push(['setCustomVariable', 2, "idAccount", account.idAccount, "visit"]);
      window['_paq'].push(['setCustomVariable', 3, "fullname", account.fullname, "visit"]);
      window['_paq'].push(['setCustomVariable', 4, "idOrganization", account["organization"].idOrganization, "visit"]);
      window['_paq'].push(['setCustomVariable', 5, "organization", account["organization"].name, "visit"]);
      window['_paq'].push(['setCustomVariable', 6, "role", account["role"].name, "visit"]);
      window['_paq'].push(['trackPageView']);
    })
    
    // angulartics2Matomo.setUserProperties({"name": "uro-local"});
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.impersonating = this.authService.isImpersonating;
    this.headerColor = environment.header_color;

    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event: any) => {
      this._navigationInterceptor(event);
    });
  }

  //ngAfterViewInit(){
  ngAfterContentChecked(){
    componentHandler.upgradeAllRegistered();
  }

  ngOnInit() {
    this.isSidenavOpened = !this.mobileQuery.matches ? true : false;
    const savedSidenavState = localStorage.getItem('sidenavState');
    if(savedSidenavState) {
      this.isSidenavOpened = savedSidenavState === 'open';
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  private _navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart && this.mobileQuery.matches) {
        this.snav.close();
    } 
  }

  onSidenavChange(opened: boolean) {
    this.isSidenavOpened = opened;
    localStorage.setItem('sidenavState', opened ? 'open' : 'closed');
  }
  
}
