import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Metric } from '../metric';
import { MetricsService } from '../metrics.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { DateFormatPipe } from '../../../pipes/date-format/date-format.pipe';
import { MetricUtils } from '../../../utils/metrics';

@Component({
  selector: 'app-metrics-list-details',
  templateUrl: './metrics-list-details.component.html',
  styleUrls: ['./metrics-list-details.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MetricsListDetailsComponent implements OnInit {
  metric: Metric;
  metrics: Metric[];
  module: string = 'Metric';
  menuTitle: string = 'Metric';
  loading: boolean = true;
  
  dateStart: Date;
  dateEnd: Date;
  dateCorrect: boolean = true;

  queryDetails: any = {pageSize: 10, pageIndex: 0, sort: 'checkTimestamp', direction: 'desc', filter: null};
  fields: Array<any>= [
    { id: 'checkTimestamp', display: 'checkTimestampFormat', header: 'Date', sortable: true, filterable: false },
    { id: 'status', display: 'icon', header: 'Status', type: 'metricsData', sortable: false, filterable: false },
  ];
  canCreate: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<MetricsListDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public metricsService: MetricsService,
    private dateFormatPipe: DateFormatPipe,
    private metricUtils: MetricUtils
    ) {
      this.metric = data;
    }

  ngOnInit() {
  }

  onTableListDetailsEmitter(message:any):void {
    switch (message.text) {
      case 'getData':
        this.queryDetails = message.data;
        this.onGetData();
        break;
    }
  }

  changeDate() {
    if (this.dateStart && this.dateEnd) {
      this.dateCorrect = moment(this.dateEnd).isSameOrAfter(moment(this.dateStart));
    }
    if ((this.dateStart && this.dateEnd && this.dateCorrect) || this.dateStart || this.dateEnd) {
      this.queryDetails.filter.filterDate = {start: this.dateStart, end: this.dateEnd, attribute: 'checkTimestamp'}
    } else {
      this.queryDetails.filter.filterDate = undefined;
    }
    this.onGetData();
  }

  clearDate(event) {
    this[event] = undefined;
    this.changeDate();
  }
      
  onGetData(){
    this.loading = true;
    const datasourceId = this.metric.datasource.id ? this.metric.datasource.id : null;
    this.metricsService.getMetricsEntityAndTypeMetric(this.metric.idMachine, this.metric.metricId, datasourceId, this.queryDetails.pageSize, this.queryDetails.pageIndex, this.queryDetails.sort, this.queryDetails.direction, this.queryDetails.filter).subscribe(async data => {
      this.loading = false;
      this.metrics = await this.dataFormatMetrics(data);
      this.metrics['rows'] = await this.metricUtils.getIconsAndMoreInfo(this.metrics['rows']);
    })
  }

  private dataFormatMetrics(metrics): Metric[] {
    const rows = metrics['rows'];
    if (rows && rows.length) {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        row.checkTimestampFormat = this.dateFormatPipe.transform(row.checkTimestamp, 'DD/MM/YYYY HH:mm:ss', true);
      }
    }
    metrics['rows'] = rows;
    return metrics
  }

}
