import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-help-calendar',
  templateUrl: './help-calendar.component.html',
  styleUrls: ['./help-calendar.component.css']
})
export class HelpCalendarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HelpCalendarComponent>,
  ) { }

  ngOnInit() {
  }

}
