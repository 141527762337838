import { Injectable }       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras,
    CanLoad, Route
}                           from '@angular/router';
import { AuthService }      from './auth.service';
import { AlertService }     from './alert.service';

// import * as Raven from 'raven-js';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService
  ) {}

  
  public canActivate(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  public canActivateChild(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  public canLoad(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  private checkLoginOrLogout(): Observable<boolean> {
    return this.authService.isLoggednIn().pipe(tap((res): boolean => {
      if (!res) {
        this.authService.logout();
      }
      return res;
    }));
  }

}
