import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }        from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { ShiftsRoutingModule }  from './shifts-routing.module';
import { ShiftListComponent }   from './shift-list/shift-list.component';
import { ShiftEditComponent}   from './shift-edit/shift-edit.component';
import { ShiftDialog } from './shift-edit/shift-edit.component';
import { ShiftService }         from './shift.service';

import { SharedModule }                 from './../shared/shared.module';
import { CustomDateAdapter } from './custom-date-adapter';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    ShiftsRoutingModule,
    SharedModule
  ],
  declarations: [
    ShiftListComponent,
    ShiftDialog,
    ShiftEditComponent
  ],
  providers: [ 
    ShiftService,
    {provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  exports: [
    ShiftListComponent,
    ShiftEditComponent
  ]
})
export class ShiftsModule {
  constructor(private dateAdapter:DateAdapter<Date>) {
    dateAdapter.setLocale('es-ES'); // DD/MM/YYYY
  }
 }
