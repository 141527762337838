import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../auth.service';
import { AppService } from '../apps/app.service';

import { QueryStore } from '../shared/store/query.store';
import { TabStore } from '../shared/store/tab.store';

import { environment } from '../../../environments/environment';

import * as _ from 'lodash';
import { OrganizationService } from '../organizations/organization.service';
import { Account } from '../accounts/account';

@Component({
  //selector: '[app-sidebar]',
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  account : any;
  url: string = "";
  leftColor = environment.header_color;

  navList: any[] = [
    {
      heading: 'Roles',
      link: '/roles',
      icon: 'pe pe-7s-id pe-3x',
      minimumRoleWeight: 1
    },
    {
      heading: 'Organizations',
      link: '/organizations',
      icon: 'vi vi-factory vi-3x',
      minimumRoleWeight: 4
    },
    {
      heading: 'Accounts',
      link: '/accounts',
      icon: 'vi vi-users vi-3x',
      minimumRoleWeight: 4
    },
    {
      heading: 'Machines',
      link: '/entities',
      icon: 'vi vi-machine vi-3x',
      minimumRoleWeight: 4
    },
    {
      heading: 'Fleet',
      link: '/fleet',
      icon: 'vi vi-fleet vi-3x',
      minimumRoleWeight: 4
    },
    {
      heading: 'Machine Availability',
      link: '/machine-availability',
      icon: 'vi vi-timeline vi-3x',
      minimumRoleWeight: 2
    },
    {
      heading: 'Machine models',
      link: '/entityModels',
      icon: 'vi vi-machine-catalog vi-3x',
      minimumRoleWeight: 2
    },
    {
      heading: 'Vixion Edge',
      link: '/dataloggerHardwares',
      icon: 'vi vi-vixion-edge vi-3x',
      minimumRoleWeight: 4
    }
  ]
  
  extraNavList = [
    {
      heading: 'Apps',
      icon: 'vi vi-vixion vi-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Apps',
          link: '/apps',
          icon: 'vi vi-vixion vi-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Views',
          link: '/views',
          icon: 'pe pe-7s-photo-gallery pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Widgets',
          link: '/widgets',
          icon: 'pe pe-7s-graph pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'WidgetGroups',
          link: '/widgetGroups',
          icon: 'pe pe-7s-graph pe-3x',
          minimumRoleWeight: 1
        },
      ]
    },
    {
      heading: 'Addons',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Master Addons',
          link: '/masterAddons',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Addon models',
          link: '/addonModels',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Addons',
          link: '/addons',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        }
      ]
    },
    {
      heading: 'Datalogger',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Datalogger models',
          link: '/dataloggerHardwareModels',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Datalogger softwares',
          link: '/dataloggerSoftwares',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        }
      ]
    },
    {
      heading: 'Datasources',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Datasources',
          link: '/datasources',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Datasource models',
          link: '/datasourceModels',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        }
      ]
    },
    {
      heading: 'Manage EULAs',
      link: '/eulas',
      matIcon: 'gavel',
      minimumRoleWeight: 1,
      pages: []
    },
    {
      heading: 'Licenses',
      link: '/licenses',
      icon: 'pe pe-7s-note pe-3x',
      minimumRoleWeight: 2,
      pages: []
    },
    {
      heading: 'Notifications',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Notification rules',
          link: '/notificationRules',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
      ]
    },
    {
      heading: 'Settings',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Master settings',
          link: '/masterSettings',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Settings',
          link: '/settings',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        }
      ]
    },
    {
      heading: 'Signal types',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Management',
          link: '/masterVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
      ]
    },
    {
      heading: 'Variables / Signals',
      icon: 'pe pe-7s-server pe-3x',
      minimumRoleWeight: 1,
      pages: [
        {
          title: 'Aggregated signals',
          link: '/aggregatedSignals',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2
        },
        {
          title: 'Signal types',
          link: '/masterVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2
        },
        {
          title: 'Management',
          link: '/masterVariables/management',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2
        },
        {
          title: 'Map variables',
          link: '/mapVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'PMCSelectors',
          link: '/PMCSelectors',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Variable types',
          link: '/variableTypes',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 1
        },
        {
          title: 'Signals',
          link: '/bbjVariables',
          icon: 'pe pe-7s-server pe-3x',
          minimumRoleWeight: 2
        },
      ]
    },
    {
      heading: 'API Documentation',
      link: '/swagger',
      icon: 'pe pe-7s-notebook pe-3x',
      minimumRoleWeight: 10,
      pages: []
    },
  ];

  public showMachineControl: boolean = false; // Temporary conditional to show metrics only to Zayer (id:3)
  public showMachineAvailability: boolean = false; // Temporary conditional to show machine availability only to Zayer (id:3)

  constructor(
    private router: Router,
    private authService: AuthService,
    private queryStore: QueryStore,
    private tabStore: TabStore,
    private appService: AppService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.authService.getAccount().subscribe((account: Account): void => {
      this.account = account;
      this.checkMachineControlShow(); // Temporary conditional to show metrics only to Zayer (id:3)
      this.checkMachineAvailabilityShow(); // Temporary conditional to show machine availability only to Zayer (id:3)
    });
  }
  
  ngAfterContentChecked(){
    //this.getRole();
    this.checkSelected();
  }
  
  private async checkMachineControlShow() {
    this.organizationService.getOrganization(this.account.idOrganization).subscribe(organization => {
      const hierachySplit = organization.hierarchy.split('/');
      const hierachyClear = _.filter(hierachySplit, _.size);
      const hierachy: number[] = [];
      for (let i = 0; i < hierachyClear.length; i++) {
        const data = hierachyClear[i];
        const newStr: string = data.replace(/-/g, '');
        hierachy.push(parseInt(newStr));
      }

      // Temporary conditional to show machine control only to Zayer and clients (id:3)
      this.showMachineControl = _.includes(hierachy, 3);
      // Temporary conditional to show machine control only to Zayer and clients (id:3)
      
      // Temporary conditional to show machine control vixion
      if (this.account.idOrganization === 1) this.showMachineControl = true;
      // Temporary conditional to show machine control vixion
    });
  }
  
  private async checkMachineAvailabilityShow() {
    if (this.account.idOrganization === 1 || this.account.idOrganization === 3) this.showMachineAvailability = true;
  }

  checkSelected(): void {
    if (this.router.url != this.url) {
      this.url = this.router.url;
    }
  }

  onSidebarClick() {
    this.queryStore.deleteAllQueries();
    this.tabStore.deleteAllTabs();
  }

  hasChildActive(navItem) {
    let active = false;
    active = navItem.pages.some((page)=>{
      return (this.router.url.startsWith(page.link));
    });
    return active;
  }

  isActive(navItem) {
    let isMyProfile = this.router.url.startsWith("/accounts/profile/");
    return this.router.url.startsWith(navItem.link) && !isMyProfile;
  }

}
