import { NgModule } from '@angular/core';
import { ShiftsUtils } from './shifts';
import { ExpressionsUtils } from './expressions';
import { CalendarUtils } from './calendar';
import { GenericUtils } from './generic';
import { MetricUtils } from './metrics';

@NgModule({
  providers: [
    ShiftsUtils,
    ExpressionsUtils,
    CalendarUtils,
    MetricUtils,
    GenericUtils
  ]
})
export class UtilsModule { }
