import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }        from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { RolesRoutingModule }  from './roles-routing.module';
import { RoleListComponent }   from './role-list/role-list.component';
import { RoleEditComponent}   from './role-edit/role-edit.component';
import { RoleService }         from './role.service';

import { SharedModule }                 from './../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    RolesRoutingModule,
    SharedModule
  ],
  declarations: [
    RoleListComponent,
    RoleEditComponent
  ],
  providers: [ 
    RoleService, 
  ],
  exports: [
    RoleListComponent,
    RoleEditComponent
  ]
})
export class RolesModule { }
