import { Injectable } from "@angular/core";
import { GenericUtils } from "./generic";

@Injectable({
  providedIn: 'root'
})
export class MetricUtils {
    
  constructor (
    private genericUtils: GenericUtils
  ) {}

  public async getIconsAndMoreInfo(metrics: any) {
      for (let i = 0; i < metrics.length; i++) {
        const metric = metrics[i];
        metric.icon = {icon: '', color: '', title: '', info: ''}
        if (metric.type === 'float') {
          if (metric.metricDown === true) {
            metric.icon.icon = 'error';
            metric.icon.color = 'orange-color';
            metric.icon.title = metric.metricValue === '0' ? 'The last metric is not correct, but metrics are out of date, machine is probably offline' : 'The last metric is correct, but metrics are out of date, machine is probably offline';
          } else {
            metric.icon.icon = metric.metricValue === '0' ? 'cancel_presentation' : 'check_circle';
            metric.icon.color = metric.metricValue === '0' ? 'red-color' : 'green-color';
            metric.icon.title = metric.metricValue === '0' ? 'The last metric is not correct' : 'The last metric is correct';
          }
        } else {
          if (metric.metricValue && metric.metricValue !== '') {
            metric.icon.icon = 'expand_more';
            for (let j = 0; j < metric.metricValue.length; j++) {
              const metricValue = metric.metricValue[j];
              if (j > 0) metric.icon.info += `<br>`
              for (const key in metricValue) {
                if (Object.prototype.hasOwnProperty.call(metricValue, key)) {
                  const element = metricValue[key];
                  metric.icon.info += `<span><strong>${this.genericUtils.capitalizeFirstLetter(key)}</strong>: ${element}</span><br>`
                }
              }
            }
            metric.icon.title = `More info`
          }
        }
      }
      return metrics;
  }
}