import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalCalendarShift } from '../global-calendar/globalCalendarShift';

@Injectable({
  providedIn: 'root'
})
export class ShiftsGlobalCalendarService {

  constructor(private http: HttpClient) { }

  /**
   * Retrieves all sglobal calendar shifts
   */
  public getShiftsGlobalCalendar(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<any[]> {
    let queryParams = new HttpParams();
    if (limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if (offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if (sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if (direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if (filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<any[]>(environment.api_url + '/shifts-global-calendar', { params: queryParams });
  };

  /**
   * Retrieves all sglobal calendar shifts
   */
  public getShiftsActiveGlobalCalendar(): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + '/shifts-global-calendar/active');
  };

  /**
   * Retrieves all sglobal calendar shifts for entity
   */
  public getShiftsActiveGlobalCalendarEntity(idEntity): Observable<any[]> {
    return this.http.get<any[]>(environment.api_url + '/shifts-global-calendar/active/entity/' + idEntity);
  };

  /**
   * Save global calendar shift
   *
   * @param shift
   */
  public saveShift(shift: GlobalCalendarShift): Observable<any> { 
    if (shift.idShift) {
      return this.updateShift(shift);
    } else {
      return this.newShift(shift);
    }
  };

  /**
   * Create new global calendar shift
   *
   * @param shift
   */
  public newShift(shift: GlobalCalendarShift): Observable<any> { 
    return this.http.post(environment.api_url + '/shifts-global-calendar', shift);
  };
  
  /**
   * Update global calendar shift
   *
   * @param shift
   */
  public updateShift(shift: GlobalCalendarShift): Observable<any> { 
    return this.http.put(environment.api_url + '/shifts-global-calendar', shift);
  };
  
  /**
   * Update global calendar shift
   *
   * @param shift
   */
  public saveGlobalCalendarGenerator(entityCalendar: any): Observable<any> { 
    return this.http.post(environment.api_url + '/global-calendar-generator', entityCalendar);
  };
  
  /**
   * Update global calendar shift
   *
   * @param shift
   */
  public updateGlobalCalendarGenerator(entityCalendar: any): Observable<any> { 
    return this.http.put(environment.api_url + '/global-calendar-generator', entityCalendar);
  };

  /**
   * update entity calendar
   * @param calendar 
   * @returns 
   */
  public updateDayGlobalCalendar(calendar: any): Observable<any> { 
    return this.http.put(environment.api_url + '/update-day-global-calendar', calendar);
  };

}
