import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../alert.service';
import { AuthService } from '../../auth.service';
import { Language } from './language';
@Injectable()
export class LanguageService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private authService: AuthService
  ){  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${environment.api_url}/languages`, this.authService.options)
      .pipe(
        catchError((err: any) => this.handleError(err))
      );
  }

  private handleError(error: any) {
    if (error instanceof ErrorEvent) {
      // Client-side error
      this.alertService.emitErrorMessage({ text: error.message, type: 'danger' });
    } else if (error instanceof Response) {
      if (error.status == 401) {
        this.router.navigate(['/login']);
      }
      this.alertService.emitErrorMessage({ text: error, type: 'danger' });
    }
    return throwError(error.error);
  }

}
