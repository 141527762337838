import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { CalendarService } from '../calendar.service';
import { Subject } from 'rxjs';
import { isSameMonth } from 'date-fns';
import { CalendarEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { filter, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDayEditComponent } from '../calendar-day-edit/calendar-day-edit.component';
import { Organization } from '../../organizations/organization';
import { AuthService } from '../../../auth.service';
import { AlertService } from '../../../alert.service';
import { CalendarGeneratorComponent } from '../calendar-generator/calendar-generator.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ShiftService } from '../../shifts/shift.service';
import { Account } from '../../accounts/account';
import { CalendarUtils } from '../../../utils/calendar';
import { ShiftsGlobalCalendarService } from '../../machine-availability/services/shifts-global-calendar.service';
import { HelpCalendarComponent } from '../help-calendar/help-calendar.component';

@Component({
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {

  @Input() organization: Organization;
  @Input() entity: any;
  private _editCalendarCheckMachineAvailability: boolean = true;
  @Input() set editCalendarCheckMachineAvailability(value: boolean) {
    this._editCalendarCheckMachineAvailability = value;
    if (this.account) this.checkCanEdit();
  }

  @ViewChild(CalendarGeneratorComponent) calendarGenerator;

  public view: CalendarView = CalendarView.Month;
  public viewDate: Date = new Date();
  public refresh: Subject<any> = new Subject();
  public events: CalendarEvent[];
  public holidays: string[] = [];
  public shifts: any[] = [];
  public account: Account;

  public cardHeaderState: string = "edit";

  public workingColor = "#ffffff";      // white
  public holidayColor = "#ffc8c8";      // light red

  public canEdit: boolean = false;
  
  public showGlobalCalendar: boolean = false;

  constructor(
    private shiftsService: ShiftService,
    private calendarService: CalendarService,
    private alertService: AlertService,
    private authService: AuthService,
    private dialog: MatDialog,
    private calendarUtils: CalendarUtils,
    private shiftsGlobalCalendarService: ShiftsGlobalCalendarService
  ) { }

  ngOnInit() {
    this.authService.getAccount().subscribe((account) => {
      this.account = account;
      this.checkCanEdit();
    });
    const date = new Date();
    this.onDateChange(date);
  }

  public checkCanEdit() {
    if (
      ((this.entity && this.entity["dataloggerSoftware"].dataloggerHardware.organization.hierarchy.includes('/-'+this.account.idOrganization+'-/')) ||
      (this.organization && this.organization.idOrganization === this.account.idOrganization || this.organization.hierarchy.includes('/-'+this.account.idOrganization+'-/'))) && 
      (this._editCalendarCheckMachineAvailability)
    ) {
      this.canEdit = true;
    }
  }

  private getWorkShifts() {
    if (this.organization) {
      this.getOrgHolidays(this.organization.idOrganization);
    } else if (this.entity) {
      this.getOrgHolidays(this.entity.dataloggerSoftware.dataloggerHardware.idOrganization);
      this.getEntityCalendar();
    }
  }

  // get holidays and set color to red
  private getOrgHolidays(organization: number) {
    this.calendarService.getOrgHolidays(
      organization
    ).pipe(filter(ws => !!ws)).subscribe((res: any)=>{
      if (res.length > 0) {
        this.holidays = res.map((h) => h.date);
        // refresh so that beforeMonthViewRender changes holiday color
        this.refresh.next();
      }
    });
  }

  // get calendar and add events 
  private getEntityCalendar() {
    this.calendarService.getEntityWorkedCalendar(
      this.entity.idEntity
    ).pipe(filter(ws => !!ws)).subscribe((res: any)=>{
      if (res.count === 0) {
        this.getGlobalCalendar();
        this.holidays = [];
      } else {
        this.generateEvents(res.rows);
      }
      this.getShifts();
    });
  }

  private getGlobalCalendar() {
    this.shiftsGlobalCalendarService.getShiftsActiveGlobalCalendarEntity(this.entity.idEntity).subscribe( data => {
      for (let i = 0; i < data.length; i++) {
          const shift = data[i];
          shift['weekDay'] = shift['weekDay'] !== undefined ? shift['weekDay'] : [false, false, false, false, false, false, false];
      }
      this.generateEventsGlobalCalendar(data);
    })
  }

  private getShifts() {
    this.shiftsService.getEntityShifts(
      this.entity["dataloggerSoftware"].dataloggerHardware.idOrganization,
      this.entity.idEntity
    ).pipe(
      map(res => res.rows)
    ).subscribe((shifts) => {
      this.shifts = shifts;
    })
  }

  // create events using workShifts weekDay and holidays
  private async generateEvents(workShifts: any[]) {
    this.calendarService.getSpecialDayShiftsWorked(this.entity.idEntity).subscribe(async res => {
      this.events = await this.calendarUtils.generateEvents(this.viewDate, workShifts, res.rows);
      this.refresh.next(this.events);
    })
  }

  // create events using workShifts weekDay and holidays
  private async generateEventsGlobalCalendar(shifts: any[]) {
    const {shiftsFormat, specialDaysWorkFormat} = await this.calendarUtils.formatShiftsGlobalCalendar(shifts);
    this.events = await this.calendarUtils.generateEvents(this.viewDate, shiftsFormat, specialDaysWorkFormat);
    this.showGlobalCalendar = true;
    this.refresh.next(this.events);
  }

  // edit organization holidays or open machine day shifts
  public dayClicked(day: any): void {
    if (!this.showGlobalCalendar) {
      // change holidays
      if (this.organization) {
        // if user has edit permission (admin and end user)
        if (this.canEdit) {
          if (!day.isWeekend) {
            const dateString = this.getDateString(day.date);
            if (day.backgroundColor === this.workingColor) {
              day.backgroundColor =  this.holidayColor;
              this.calendarService.addHoliday({idOrganization: this.organization.idOrganization, date: dateString}).subscribe(
                response => { 
                  this.alertService.emitErrorMessage({ text: 'Saved holiday', type: 'info' });
                },
                error => { 
                  this.alertService.emitErrorMessage({ text: 'Error trying to save holiday', type: 'info' });
                }
              );
            } else {
              day.backgroundColor =  this.workingColor;
              this.calendarService.removeHoliday(
                this.organization.idOrganization,
                dateString
              ).subscribe(
                response => { 
                  this.alertService.emitErrorMessage({ text: 'Holiday removed', type: 'info' });
                },
                error => {
                  this.alertService.emitErrorMessage({ text: 'Error trying to remove holiday', type: 'info' });
                }
              );
            }
          }
        }
      // open dialog with day event details
      } else if (this.entity) {
        if (isSameMonth(day.date, this.viewDate)) {
          if (this.canEdit) {
            if (this.shifts.length > 0) {
              this.viewDate = day.date;
              const dialogRef = this.dialog.open(CalendarDayEditComponent, {
                width: '350px',
                data: {
                  date: day.date,
                  organization: (this.organization) ? this.organization.name : undefined,
                  workShifts: day.events.map((e) => {
                    return {
                      name: e.meta.shift.name,
                      start: this.getShiftString(e.start),
                      end: this.getShiftString(e.end),
                      worked: e.worked,
                      idShift: e.meta.shift.idShift,
                      idEntity: this.entity.idEntity,
                      idEntityWorkedShift: e.meta.idEntityWorkedCalendar,
                      date: this.getDateString(day.date)
                    };
                  }),
                  shifts: this.shifts.map((shift) => {
                    return {
                      name: shift.name,
                      start: shift.start,
                      end: shift.end,
                      idShift: shift.idShift,
                      idEntity: this.entity.idEntity,
                      worked: false,
                      date: this.getDateString(day.date)
                    };
                  })
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result) {
                  if (this.canEdit) {
                    this.onDateChange(this.viewDate);
                  } else {
                    this.alertService.emitErrorMessage({ text: 'You can only view the calendar.', type: 'info' });
                  }
                }
              });
            } else {
              this.alertService.emitErrorMessage({ text: 'You need to configure shifts from the organization section to configure them in the machine calendar.', type: 'info' });
            }
          } else {
            this.alertService.emitErrorMessage({ text: 'You can only view the calendar.', type: 'info' });
          }
        }
      }
    } else {
      this.alertService.emitErrorMessage({ text: 'The global calendar is being displayed and cannot be edited for a machine. To modify it go to the global calendar section in the side menu.', type: 'info' });
    }
  }

  onEdit() {
    if (this.shifts.length > 0) {
      this.cardHeaderState = 'new';
    } else {
      this.alertService.emitErrorMessage({ text: 'You need to configure shifts from the organization section to configure them in the machine calendar.', type: 'info' });
    }
  }

  onHelp() {
    const dialogRef = this.dialog.open(HelpCalendarComponent);
  }

  onCancel() {
    this.cardHeaderState = 'edit';
    this.getWorkShifts();
    this.refresh.next();
  }

  // call save function. calendar generator will call reload once finished
  onSave() {
    this.calendarGenerator.submitCalendar();
  }

  // calendar generator will emmit reload once the calendar is saved
  reload() {
    this.cardHeaderState = 'edit';
    this.getWorkShifts();
    this.showGlobalCalendar = false;
    this.refresh.next();
  }

  // month/data changed, load new data
  public onDateChange(date) {
    this.getWorkShifts();
  }

  // get array of dates of the selected month-year
  private getDaysInMonth (month, year): Date[] {
    return (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)
  }

  // get string from date
  private getDateString(date: Date): string {
    return date.getFullYear()
    +"-"
    +("00"+(date.getMonth()+1)).slice(-2)
    +"-"
    +("00"+date.getDate()).slice(-2);
  }

  // get string from time
  private getShiftString(date: Date): string {
    return ("00"+date.getHours()).slice(-2)
    +":"
    +("00"+date.getMinutes()).slice(-2)
    +":"
    +("00"+date.getSeconds()).slice(-2);
  }

  // change background color
  private beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      const dayString = this.getDateString(day.date);
      if (day.isWeekend || this.holidays.includes(dayString)) {
        day.backgroundColor = this.holidayColor;
      } else {
        day.backgroundColor = this.workingColor;
      }
    });
  }

}
