import { Component,
         OnInit,
         Output,
         Inject
       } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params, NavigationExtras }           from '@angular/router';
import { Location }                                 from '@angular/common';

import { Role }        from '../../roles/role';
import { Organization }          from '../../organizations/organization';
import { Language } from '../language';
import { App } from '../../apps/app';

import { Account }        from '../account';
import { AccountService } from '../account.service';
import { AlertService }    from './../../../alert.service';
import { AuthService } from './../../../auth.service';

declare var _:any;
@Component({
  selector: 'account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.css'],
  providers: [ AccountService ]
})
export class AccountEditComponent implements OnInit {

  errorMessage: string;
  account: Account;
  roles: Role[];
  initialRoles: Role[];
  organizations: Organization[];
  languages: Language[];
  apps: App[];
  accountApps: App[];
  organizationApps: App[];
  confirmPassword: string;
  isMyAccount: boolean = false;
  @Output() editMode: boolean = false;
  module: string = 'Account';
  cardHeaderState: string;
  initialCardHeaderState: string;
  isAdmin: boolean = false;
  isProvider: boolean = false;
  isEndUser: boolean = false;
  operatorAccount: boolean = false;
  showEmail: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    public authService: AuthService,
    private alertService: AlertService,
    private _location: Location,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.authService.getAccount().subscribe((account)=>{
      if (account['role'].weight == 1) {
        this.isAdmin = true;
        this.cardHeaderState = "impersonate";
        this.initialCardHeaderState = "impersonate";
      }
      if (account['role'].weight == 2) {
        this.isProvider = true;
        this.cardHeaderState = "edit";
        this.initialCardHeaderState = "edit";
      }
      if (account['role'].weight > 2) {
         this.isEndUser = true;
         this.cardHeaderState = "edit";
         this.initialCardHeaderState = "edit";
      }

      this.route.data
      .subscribe((data: { account: Account, roles: Role[], organizations: Organization[], languages: Language[], apps: App[], accountApps: App[], organizationApps: App[] }) => {
        if(data.account) {
          this.account = data.account;
          this.confirmPassword = this.account.password;
          if(this.account.idAccount == account.idAccount) {
            this.isMyAccount = true;
          }

          if (this.isEndUser) {
            this.cardHeaderState = 'disabled';
          }

          if (this.isMyAccount) {
            this.cardHeaderState = "onlyEdit";
            this.initialCardHeaderState = "onlyEdit";
          }
          if (this.account["role"].name.toLowerCase() == 'operator') {
            this.operatorAccount = true;
          }

          this.showEmail = this.isAdmin;
        } else {
          if (this.isEndUser) {
            this.router.navigate(['/']);
          }else {
            this.account = new Account(null, null, null, null, null, null, null, null, false);
            this.editMode = true;
            this.cardHeaderState = 'new';
            this.initialCardHeaderState = "onlyEdit";
            this.showEmail = true;
          }
        }
        this.roles = data.roles['rows'];
        this.initialRoles = this.roles;
        this.organizations = data.organizations['rows'];
        this.languages = data.languages;
        this.organizationApps = data.organizationApps;
      });
    });
  }

  onheaderActionEmitter(message:any):void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        break;
      case 'save':
        this.onSave(this.account);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        break;
      case 'back':
        this.onCancel();
        break;
      case 'impersonate':
        this.onImpersonate(this.account.idAccount);
        break;
    }
  }

  compareWithFunc(c1: App, c2: App): boolean {
    return c1 && c2 ? c1.idApp === c2.idApp : c1 === c2;
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(account: Account): void {
    this.checkUsername().then( () => {
      this.confirmPasswords().then(() => {
        if (!account.idRole && this.isAdmin) {
          this.alertService.emitErrorMessage({text: 'Role is required. Please fill out the field and resubmit the form', type: 'danger'});
          this.editMode = !this.editMode;
        } else if (!account.idOrganization) {
          this.alertService.emitErrorMessage({text: 'Organization is required. Please fill out the field and resubmit the form', type: 'danger'});
          this.editMode = !this.editMode;
        } else {
          this.accountService.saveAccount(account).subscribe(
            response => {
              this._location.back();
            },
            error =>  {
              console.log(error)
              this.alertService.emitErrorMessage({text: error.error, type: 'danger'});
              this.editMode = !this.editMode;
              this.errorMessage = <any>error
            });
        }

      }).catch((error) => this.errorMessage = <any>error)
    }).catch((error) => this.errorMessage = <any>error)

  }

  onDelete(): void {
    this.accountService.removeAccount(this.account.idAccount).subscribe(
      response => {
        this._location.back();
      },
      error => {
        this.alertService.emitErrorMessage({text: error.error, type: 'danger'});
        this.errorMessage = <any>error
      });
  }

  onImpersonate(idAccount) {
    this.authService.impersonate(idAccount)
        .subscribe(() => {
          // if (this.authService.isLoggedIn) {
            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/';

            // Set our navigation extras object
            // that passes on our global query params and fragment
            let navigationExtras: NavigationExtras = {
              //preserveQueryParams: true,
              queryParamsHandling: 'preserve',
              preserveFragment: true
            };

            // // Redirect the account
            // this.router.navigated = false;
            this.router.navigate([redirect]
              , navigationExtras
            );
            location.reload();
          // }
        });
  }

  onChangeOrg(event: any, organization: any){
    //check if is bit to activate the new input
    if(event.isUserInput){
      organization;
      this.roles = _.filter(this.initialRoles, function(o) {
        return o.weight >= organization.role.weight;
      });
    }
  }

  onChangeRole(event: any, role: any){
    //check if is bit to activate the new input
    if(event.isUserInput){
      event;
      role;
      if (role.name.toLowerCase() == 'operator') {
        this.operatorAccount = true;
      } else {
        this.operatorAccount = false;
        this.account.operator = null;
      }
    }
  }

  confirmPasswords(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if ( this.cardHeaderState == 'new' && !this.account.password ) {
        this.alertService.emitErrorMessage({text: 'Password is required. Please fill out the field and resubmit the form', type: 'danger'});
        this.editMode = !this.editMode;
        reject();
      }
      else if (this.account.password != this.confirmPassword) {
        this.alertService.emitErrorMessage({text: 'Password does not match', type: 'danger'});
        this.editMode = !this.editMode;
        reject();
      } else {
        resolve(true);
      }
    });
  }

  checkUsername(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let EMAIL_REGEXP =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(EMAIL_REGEXP.test(this.account.username)) {
        resolve(true);
      } else {
        this.alertService.emitErrorMessage({text: 'Invalid email format', type: 'danger'});
        this.editMode = !this.editMode;
        reject();
      }
    });
  }

  public openInfo() {
    if (this.editMode) {
      const message = '<p>When the machines have shifts configured in their calendar, you can view the data that is produced on the machine in 2 different ways.</p><ul><li>Natural visualization. It will show the data produced in a natural period of time. (Ex: 00:00 to 23:59)</li><li>Calendar visualization. It will show the data produced during the shifts. (Ex: Shift from 7:00 to 15:00)</li></ul>'
      const data = {
        title: 'Default data display',
        message: message,
      }
      this.dialog.open(DialogComponent, {data: data});
    }
  }
}

//DIALOG COMPONENT
@Component({
  selector: '[dialogtest]',
  template: `
    <h1 mat-dialog-title style="margin-bottom: 1rem; font-size: 2rem; font-weight: bold;">{{data.title}}</h1>
    <mat-dialog-content>
      <ng-container *ngIf="data.message">
        <div [innerHTML]="data.message"></div>
      </ng-container>
    </mat-dialog-content>
  `
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
