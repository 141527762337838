import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class CalendarUtils {

    // create events using workShifts weekDay and holidays
    public async generateEvents(viewDate, workShifts: any[], specialDayWorked) {
        let events = [];
        workShifts = _.orderBy(workShifts, ['shift.start'], ['asc']);
        const monthDays = this.getDaysInMonth(viewDate.getMonth()+1, viewDate.getFullYear());
        monthDays.forEach((day) => {
          const dayString = this.getDateString(day);
          const dayMoment = moment(dayString, 'YYYY-MM-DD');
    
          let workShiftClone = _.cloneDeep(workShifts);
    
          for (let i = 0; i < workShiftClone.length; i++) {
            const workShift = workShiftClone[i];
    
            if (workShift.shift['historicalChangesShift'] && workShift.shift['historicalChangesShift'].length > 0) {
              for (let j = 0; j < workShift.shift['historicalChangesShift'].length; j++) {
                const historicalShift = workShift.shift['historicalChangesShift'][j];
                const dateMoment = moment(historicalShift.date, 'YYYY-MM-DD');
                const isSameOrAfter = dayMoment.isSameOrAfter(dateMoment);
                if (isSameOrAfter && historicalShift.startDayBefore !== null) {
                  workShift.startDayBefore = historicalShift.startDayBefore;
                } 
              }
            }
    
            if (workShift['historicalChanges'] && workShift['historicalChanges'].length > 0) {
              for (let j = 0; j < workShift['historicalChanges'].length; j++) {
                const historicalShift = workShift['historicalChanges'][j];
                const dateMoment = moment(historicalShift.date, 'YYYY-MM-DD');
                const isSameOrAfter = dayMoment.isSameOrAfter(dateMoment);
                if (isSameOrAfter && historicalShift.startDayBefore !== null) {
                  workShift.startDayBefore = historicalShift.startDayBefore;
                } 
              }
            }
          }
    
          workShiftClone = _.orderBy(workShiftClone, ['startDayBefore', 'shift.start'], ['desc', 'asc']);
    
          workShiftClone.forEach((ws)=>{
            let start = new Date(day);
            const startTime = ws.shift.start.split(/\D/);
            start.setHours(+(startTime[0]));
            start.setMinutes(+startTime[1]);
            start.setSeconds(+startTime[2]);
    
            let end = new Date(day);;
            const endTime = ws.shift.end.split(/\D/);
            end.setHours(+(endTime[0]));
            end.setMinutes(+endTime[1]);
            end.setSeconds(+endTime[2]);
            
            const weekDay = day.getDay() === 0 ? 6 : day.getDay() -1;
            
            ws.historicalChanges = _.orderBy(ws.historicalChanges, ['date'], ['asc']);
            if (ws.historicalChanges && ws.historicalChanges.length > 0) {
              for (let i = 0; i < ws.historicalChanges.length; i++) {
                const historical = ws.historicalChanges[i];
                const isSameOrAfter = moment(dayString).isSameOrAfter(historical.date);
                if (isSameOrAfter) {
                  ws.weekDay = historical.weekDay;
                }
              }
            }
            
            const isWorkedDay = ws.weekDay[weekDay];
            if (isWorkedDay) {
              events.push({
                start,
                end,
                title: ws.shift.name,
                color: {
                  primary: ws.shift.color,
                  secondary: ws.shift.color,
                },
                worked: !ws.holidays || !ws.holidays.includes(dayString),
                meta: ws
              });
            }
          });
        });

        const days = await this.getSpecialDayShiftsWorked(specialDayWorked, events);
      
        if (days.length > 0) {
          events = events.concat(days);
        }
    
        return events;
    }

    // get array of dates of the selected month-year
    private getDaysInMonth (month, year): Date[] {
        return (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)
    }

    // get string from date
    private getDateString(date: Date): string {
        return date.getFullYear()
        +"-"
        +("00"+(date.getMonth()+1)).slice(-2)
        +"-"
        +("00"+date.getDate()).slice(-2);
    }

    private getSpecialDayShiftsWorked(data, events) {
        const daysWorked: any[] = [];
        for (let i = 0; i < data.length; i++) {
            const day = data[i];
            let start = new Date(day.date);
            const startTime = day.shift.start.split(/\D/);
            start.setHours(+(startTime[0]));
            start.setMinutes(+startTime[1]);
            start.setSeconds(+startTime[2]);
    
            let end = new Date(day.date);;
            const endTime = day.shift.end.split(/\D/);
            end.setHours(+(endTime[0]));
            end.setMinutes(+endTime[1]);
            end.setSeconds(+endTime[2]);

            const dataFormat = {
                start,
                end,
                title: day.shift.name,
                color: {
                    primary: day.shift.color,
                    secondary: day.shift.color,
                },
                worked: day.worked,
                meta: day
            }
            const index = _.findIndex(events, event => {
                return moment(event.start).isSame(moment(dataFormat.start)) && moment(event.end).isSame(moment(dataFormat.end)) && event.meta.idShift === dataFormat.meta.idShift;
            })
            if (index === -1) daysWorked.push(dataFormat);
        }
        return daysWorked;
    }

    public formatShiftsGlobalCalendar(shifts) {
        const specialDaysWorkFormat = [];
        for (let i = 0; i < shifts.length; i++) {
          const shift = shifts[i];
          shift['holidays'] = shift.dayFree;
          shift['shift'] = shift;
          for (let j = 0; j < shift.specialDayWork.length; j++) {
            const dateString = shift.specialDayWork[j];
            const specialDayWorkFormat = {
              date: dateString,
              idShift: shift.idShift,
              worked: true,
              shift: shift
            }
            specialDaysWorkFormat.push(specialDayWorkFormat);
          }
        }
        return {shiftsFormat: shifts, specialDaysWorkFormat};
    }
}