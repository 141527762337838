import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { CalendarGeneratorComponent } from './calendar-generator/calendar-generator.component';
import { CalendarService } from './calendar.service';
import { ShiftService } from '../shifts/shift.service';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarDayEditComponent } from './calendar-day-edit/calendar-day-edit.component';
import { UtilsModule } from '../../utils/utils.module';
import { HelpCalendarComponent } from './help-calendar/help-calendar.component';

@NgModule({
  imports: [
    CommonModule,
    MatGridListModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDialogModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    UtilsModule
  ],
  declarations: [
    CalendarGeneratorComponent,
    CalendarComponent,
    CalendarDayEditComponent,
    HelpCalendarComponent,
  ],
  providers: [
    CalendarService,
    ShiftService,
  ],
  exports: [
    CalendarGeneratorComponent,
    CalendarComponent
  ]
})
export class VixCalendarModule { }
